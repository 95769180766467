const breakpoints = ["640px", "960px", "1280px"];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];

export const theme = {
  colors: {
    foreground: "#000000",
    background: "#ffffff",
    midground: "#999999",
    offForeground: "#DDDDDD",
    error: "#FF3333",
  },
  //      0  1  2   3   4   5   6   7   8   9    10
  space: [0, 5, 10, 15, 20, 30, 40, 60, 80, 120, 160],
  breakpoints,
  fonts: {
    main: "neue-haas-grotesk-display, sans-serif",
    bold: "neue-haas-grotesk-display, sans-serif",
  },
  mediaQueries: {
    small: `@media screen and (min-width: ${breakpoints.sm})`,
    medium: `@media screen and (min-width: ${breakpoints.md})`,
    large: `@media screen and (min-width: ${breakpoints.lg})`,
  },
  capSizes: {
    main: {
      height: 0.72,
      offset: -0.01,
    },
    bold: {
      height: 0.72,
      offset: -0.01,
    },
  },
};
