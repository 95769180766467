// Returns process steps
export const HOW = "how";
export const CHOOSE = "choose";
export const ADD_DETAILS = "addDetails";
export const ADD_RETURN_REASONS = "addReturnReasons";

// Header text themes
export const LIGHT = "light";
export const DARK = "dark";

// Header sizes

export const LARGE = "large";
export const NARROW = "narrow";

export const DONATIONS = "Donations";

// Incentive types
export const ITEM_BASED = "item_based";
export const FIXED_VALUE = "fixed_value";
export const FIXED_PERCENTAGE = "fixed_percentage";

// Label options
export const PRINT_AT_HOME = "print_at_home";

// Takeback item statuses
export const PENDING = "pending";
export const APPROVED = "approved";
export const MISSING = "missing";
export const NON_BRAND = "non_brand";
export const NON_COMPLIANT = "non_compliant";

// Error handling
export const ERROR = "Error";

// Shipping
export const MAX_WEIGHT = 15000;

// Instore takeback UI
export const MINIMAL = "minimal";
export const FULL = "full";

// Brand slugs with custom fonts
const MARKS_AND_SPENCER_SLUG = "marks-and-spencer";
const MARKS_SPENCER_SLUG = "marks-spencer";

// Brand font weights with custom fonts
const MARKS_AND_SPENCER_REGULAR = "marks-and-spencer-regular, sans-serif";
const MARKS_AND_SPENCER_BOLD = "marks-and-spencer-bold, sans-serif";

// Brands with custom fonts

// Schema:
// {
//   slugs: [string],
//   fonts: {
//     main: string,
//     bold: string,
//   },

const MARKS_AND_SPENCER = {
  slugs: [MARKS_AND_SPENCER_SLUG, MARKS_SPENCER_SLUG],
  fonts: { main: MARKS_AND_SPENCER_REGULAR, bold: MARKS_AND_SPENCER_BOLD },
};

export const BRANDS_WITH_CUSTOM_FONTS = [MARKS_AND_SPENCER];
